<template>
  <div class="page-main-box pt-24 pr-16">
    <!-- 有数据 -->
    <div class="pl-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div class="table-height" :style="{ height: tableHeight + 'px' }">
        <p-table :dataSource="tableDataSource" height="auto">
          <p-t-column type="seq" width="60" title="序号" />
          <p-t-column field="driver_name" min-width="128" title="司机姓名" />
          <p-t-column field="driver_mobile" min-width="146" title="手机号码" />
          <p-t-column field="card_no" min-width="150" title="油卡卡号" />
          <p-t-column
            field="balance"
            min-width="150"
            title="账户余额"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.balance / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>

          <p-t-column
            field="payment_amount"
            min-width="150"
            title="消费金额"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.payment_amount / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>

          <p-t-column
            field="order_count"
            min-width="90"
            title="订单数"
            align="right"
          />
          <p-t-column
            field="oil_quantity"
            min-width="130"
            title="加油量（升）"
            align="right"
          />
          <p-t-column
            field="gas_quantity"
            min-width="130"
            title="加气量（千克）"
            align="right"
          />
          <p-t-column
            field="bind_company_count"
            min-width="130"
            title="关联企业数"
            align="right"
          />
          <p-t-column
            field="card_count"
            min-width="120"
            title="油卡数量"
            align="right"
          />
          <p-t-column width="156" title="操作" fixed="right">
            <template v-slot="{ row }">
              <Button type="link" @click="handleToDetail(row)">
                账户详情
              </Button>
              <Button
                v-if="$store.getters.roles.includes('ConsumerOrders')"
                type="link"
                @click="handleToOrder(row)"
              >
                查看订单
              </Button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { Button } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useRouter } from "vue-router";
import { useGasCardPageApi } from "@/apis/oil";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useSearchForm from "@/hooks/UseSearchForm";
import useTableHeight from "@/hooks/useTableHeight";

export default {
  name: "enterprise-oil-account",
  components: { Button },
  setup() {
    const router = useRouter();
    const gasCardPageApi = useGasCardPageApi();

    // table高度
    const tableHeight = ref();

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    let schemaList = searchHeaderOptions;

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          driver_name: filter.value.driver_name
            ? filter.value.driver_name
            : null,
          owner_type: "DRIVER",
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(gasCardPageApi(data));
        return useTableDataRecords(res);
      }
    });

    // 跳转账户详情页
    const handleToDetail = row => {
      router.push({
        path: "/oil/driver-oil-account-detail",
        query: {
          driver_id: row.driver_id
        }
      });
    };

    // 跳转到订单页
    const handleToOrder = row => {
      router.push({
        path: "/oil/consumer-orders",
        query: {
          driver_id: row.driver_id
        }
      });
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      handleToDetail,
      tableDataSource,
      handleFormSearch,
      handleToOrder,
      schema: searchHeaderOptions
    };
  }
};
</script>

<style lang="less" scoped></style>
